<template>
  <div class="container" style="background: #F5FFEF">
    <div class="bottom">
      <img src="../../static/bottomlogo.png" />
    </div>
    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header class="header">
        <loginheader :activekey="3"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content >
      <div class="newscontain">
        <div class="newslist">
          <div class="top_content">
            <div class="title">
              <h3>宝宝吃鸡蛋指南 ，干货收藏</h3>
            </div>
            <div class="titledes">
              <div class="date">
                <p>2022-03-16 22:36</p></div>
              <div class="cellection"><p>浏览量124.8万</p></div>
            </div>
          </div>
          <div class="center_content">
            <p>鸡蛋的好处有多少，我就不必多说了吧。生活中，很多家长会把鸡蛋给宝宝当辅食吃，想给他补充营养。但对于小
              来说，吃鸡蛋有一定的讲究，如果吃鸡蛋方法不正确，会影响身体健康和正常发育。今天小编给大家科普给宝宝吃鸡蛋
              的科学指南，赶紧来收藏！
              宝宝6个月后就可以吃辅食了，建议大家先给宝宝吃米粉，适应后再试试吃蛋黄。为什么不吃蛋清？这是因为宝宝肠
              还没有发育完善，消化道粘膜屏障的发育还不成熟。蛋清中含有的蛋白质分子非常小，容易穿过婴儿的肠粘膜进入到血
              液，导致宝宝发生过敏，甚至引发湿疹、荨麻疹等问题。
              一岁以下宝宝可以从配方奶或母乳摄取充足的钠满足身体发育所需，吃鸡蛋时无需额外加盐。宝宝一岁后，妈妈可
              以在鸡蛋羹中适当添加一点盐，最好不要添加。让宝宝保持清淡饮食，有利于他的生长发育。
              鸡蛋容易被细菌附着，吃之前一定要把它煮熟。如果鸡蛋没有煮熟，宝宝吃了后会引发腹泻、细菌感染等。而且鸡
              蛋煮熟后，里面的营养成分会变得疏散起来，有利于消化吸收。
            </p>
            <img src="../../static/yxxx.jpg" >
          </div>
        </div>
      </div>

    </a-layout-content>

    <a-layout-footer style="padding: 0">
      <loginfooter></loginfooter>
    </a-layout-footer>
  </div>
</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "classcontent",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return{}
  },methods:{

  }
}
</script>

<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);
  box-sizing: border-box;
}
.newscontain{
  background: #ffffff;
  width: 34rem;
  margin: 1rem auto 1.5rem;
  height: 22rem;
  box-shadow: 0px 0px 18px 0px rgba(8, 68, 31, 0.26);
  border-radius: 1.7rem;
  .newslist{
    width:31rem;
    margin:1.3rem auto 0.9rem;
    .top_content{
      height: 3.3rem;
      border-bottom: 1px solid #08441F;
      .title{
        h3{
          font-size: 0.9rem;
          color: #333333;
          font-family: SourceHanSansSC;
          text-align: center;
          padding-top: 0.5rem;
        }
      }
      .titledes{
        display: flex;
        flex-direction: row;
        margin: 0 auto;
        width: 10rem;

        justify-content: space-between;
        p{
          font-size:0.5rem;
          line-height: 0.5rem;
          color: #333333;
        }
      }
    }
    .center_content{
      margin-top: 1.1rem;
      font-size: 0.5rem;
      color: #333333;
      font-family: SourceHanSansSC;
      overflow-y: auto;
      height: 15rem;
      img{
        width: 100%;
      }
    }
  }
}
.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

  bottom:0;
  right: 0;
  width:11.8rem;
  img{
    width: 100%;
  }

}

</style>